import {
  Account,
  Connection,
  PublicKey,
  Keypair,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import BN from "bn.js";

import * as anchor from '@project-serum/anchor';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer, getTokenAccountForTokenAndOwner } from "@/libs/solanaConnection";
import { anchor_init, get_pda, prepare_ata } from "@/libs/solanaProgram";
import { signAndSendTransaction, signAndSendMultipleTransactions } from "@/libs/wallet";
const mpl_token_metadata = require('@metaplex-foundation/mpl-token-metadata');

import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, Token, getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, createTransferCheckedInstruction, MintLayout  } from "@solana/spl-token";

import _idl from "@/IDL.json";
const idl = _idl;

// Address of the deployed program.
const program_id = new PublicKey('7DujNcbL1Q6tU4dvVjoc7sbYxF7CxC2mRwm1NWDQ94vo');

var connection = null;


import axios from 'axios';
import bs58 from "bs58";

const VAULT_ACCOUNT_SEED = 'collection owner';


let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}
	
/**
 * 
 * Find the PDA for the metadata
 * 
 */
async function find_pda(mint_pubkey) {
	
	const [pda_metadata, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('metadata'),
			new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s').toBuffer(), 
			mint_pubkey.toBuffer(),
		],
		new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')
	);
	
	return pda_metadata;
}

/**
 * 
 * Find the PDA for the edition
 * 
 */
async function find_pda_edition(mint_pubkey) {
	
	const [pda_edition, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('metadata'),
			new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s').toBuffer(), 
			mint_pubkey.toBuffer(),
			Buffer.from('edition'),
		],
		new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')
	);
	
	return pda_edition;
}

/**
 * 
 * Find the PDA for the collection owner
 * 
 */
export async function find_pda_collection_owner() {
	
	const [pda_collection_owner, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('collection owner'),
		],
		program_id
	);
	
	return pda_collection_owner;
}

/**
 * 
 * Find the PDA for the collection owner (authority)
 * 
 */
async function find_pda_collection_authority() {
	
	const [pda_collection_authority, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('collection owner authority'),
		],
		program_id
	);
	
	return pda_collection_authority;
}

/**
 * 
 * Create the instruction to create the metadata
 * 
 */
async function prepare_metadata_instruction(wallet_address, mint, item_metadata, collection_address) {
	
	var pda_metadata = await find_pda(mint.publicKey);
	
	var accounts = {
		metadata: pda_metadata, // OK
		mint: mint.publicKey, // OK
		mintAuthority: wallet_address,
		payer: wallet_address,
		updateAuthority: wallet_address,
	};
	
	var metadata = item_metadata.metadata;
	metadata.creators = item_metadata.creators;
	
	if(!collection_address) {
		
		metadata.CollectionDetails = {V1: {size: 1000}};
		metadata.collection = null;
	}
	else {
		
		metadata.collection = {
			
			key: collection_address,
			verified: false
		};
	}
	
	metadata.uses = null;
	
	var args = {
		createMetadataAccountArgsV2: {
			data: metadata,
			isMutable: true,
		},
	};
	
	var metadata_instruction = await mpl_token_metadata.createCreateMetadataAccountV2Instruction(accounts,args);
	
	return metadata_instruction;
}

async function prepare_create_account_instruction(wallet_address, mint) {
	
	return await SystemProgram.createAccount({
		fromPubkey: wallet_address,
		newAccountPubkey: mint.publicKey,
		lamports: await Token.getMinBalanceRentForExemptMint(connection),
		space: MintLayout.span,
		programId: TOKEN_PROGRAM_ID,
	});
}

async function prepare_init_mint_instruction(wallet_address, mint) {
	
	// var pda = await find_pda_collection_owner(mint.publicKey);
	
	return await Token.createInitMintInstruction(
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		0,
		wallet_address, // mint auth
		wallet_address // freeze auth
		// pda,
		// pda,
	);
}

async function prepare_create_master_edition_v3_instruction(wallet_address, mint) {
	
	// var pda = await find_pda_collection_owner(mint.publicKey);
	
	var accounts = {
		edition: await find_pda_edition(mint.publicKey),
		mint: mint.publicKey,
		updateAuthority: wallet_address,
		mintAuthority: wallet_address,
		payer: wallet_address,
		metadata: await find_pda(mint.publicKey)
	};
	
	var args = {
		createMasterEditionArgs: {
			maxSupply: 0,
		},
	};
	
	return await mpl_token_metadata.createCreateMasterEditionV3Instruction(accounts, args);
}

async function prepare_create_ata_instruction(wallet_address, mint) {
	
	// create associated token account
	const ata_mint = await Token.getAssociatedTokenAddress(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		wallet_address,  // to
		true
	);
	
	const create_ata_instruction = Token.createAssociatedTokenAccountInstruction(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		ata_mint,
		wallet_address, // to
		wallet_address // signer
	);
	
	return create_ata_instruction;
}

async function prepare_create_mintto_instruction(wallet_address, mint) {
	
	// create associated token account
	const ata_mint = await Token.getAssociatedTokenAddress(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		wallet_address,  // to
		true
	);
	
	const mint_to_instruction = Token.createMintToInstruction(TOKEN_PROGRAM_ID, mint.publicKey, ata_mint, wallet_address, [], 1);
	
	return mint_to_instruction;
}

async function prepare_program_mint_instruction(wallet_provider, wallet_address, mint, collection_address, escrow_address, mint_type, escrow_wl_address) {
	
	const ata_mint = await Token.getAssociatedTokenAddress(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		wallet_address,  // to
		true
	);
	
	var token_address = "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263";
	var spl_token_treasury_account = "13vDcLXbY3of4a1WhuWbRKfK4b1e6DwzYoY1Be3nQNMZ";
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var accounts = {
		
		payer: wallet_address,
		mint: mint.publicKey,
		payerAta: ata_mint,
		mplProgram: new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
		metadata: await find_pda(mint.publicKey),
		pda: await find_pda_collection_owner(mint.publicKey),
		collectionMint: collection_address,
		collectionMetadata: await find_pda(collection_address),
		collectionMasteredition: await find_pda_edition(collection_address),
		
		creator1: new PublicKey("8SZTojxgUPmKDkiBm4rg5K7tm6c1STLvn7L2a68jn9AA"),
		creator2: new PublicKey("AuToz9gEG4f4fh67zo2EiwFKvW6tneMYE6UNDmX95nrL"),
		escrowAccount: escrow_address,
		
		systemProgram: anchor.web3.SystemProgram.programId,
		rent: anchor.web3.SYSVAR_RENT_PUBKEY,
		tokenProgram: TOKEN_PROGRAM_ID,
	};
	
	console.log({
		
		payer: wallet_address.toString(),
		mint: mint.publicKey.toString(),
		payerAta: ata_mint.toString(),
		mplProgram: new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
		metadata: await find_pda(mint.publicKey),
		pda: accounts.pda.toString(),
		collectionMint: collection_address.toString(),
		collectionMetadata: accounts.collectionMetadata.toString(),
		collectionMasteredition: accounts.collectionMasteredition.toString(),
		
		creator1: new PublicKey("8SZTojxgUPmKDkiBm4rg5K7tm6c1STLvn7L2a68jn9AA"),
		creator2: new PublicKey("AuToz9gEG4f4fh67zo2EiwFKvW6tneMYE6UNDmX95nrL"),
		escrowAccount: escrow_address,
		
		systemProgram: anchor.web3.SystemProgram.programId,
		rent: anchor.web3.SYSVAR_RENT_PUBKEY,
		tokenProgram: TOKEN_PROGRAM_ID,
	});
	
	var instruction;
	
	if(mint_type == 'public') {
		
		accounts.splToken = new PublicKey(token_address);
		accounts.splTreasury = new PublicKey(spl_token_treasury_account);
		accounts.splSource = new PublicKey(await getTokenAccountForTokenAndOwner(token_address, wallet_address));
		
		instruction = await program.instruction.mintNft({accounts: accounts});
	}
	else if(mint_type == 'wl_unlimited') {
		
		accounts.splToken = new PublicKey(token_address);
		accounts.splTreasury = new PublicKey(spl_token_treasury_account);
		accounts.splSource = new PublicKey(await getTokenAccountForTokenAndOwner(token_address, wallet_address));
		accounts.escrowAccountWl = new PublicKey(escrow_wl_address);
		
		instruction = await program.instruction.mintNftWithWl({accounts: accounts});
	}
	else if(mint_type == 'wl_limited') {
		
		accounts.escrowAccountWl = new PublicKey(escrow_wl_address);
		
		instruction = await program.instruction.mintNftWithWlFree({accounts: accounts});
	}
	
	return instruction;
}

export async function create_collection(wallet_provider, wallet_address, metadata) {
	
	if(!connection)
		connection = await establishConnection();
	
	var mint = await Keypair.generate();
	wallet_address = new PublicKey(wallet_address);
	
	var metadata_instruction = await prepare_metadata_instruction(wallet_address, mint, metadata, null);
	var create_account_instruction = await prepare_create_account_instruction(wallet_address, mint);
	var init_mint_instruction = await prepare_init_mint_instruction(wallet_address, mint);
	var create_ata_instruction = await prepare_create_ata_instruction(wallet_address, mint);
	var mint_to_instruction = await prepare_create_mintto_instruction(wallet_address, mint);
	var create_master_edition_v3_instruction = await prepare_create_master_edition_v3_instruction(wallet_address, mint);
	
	// set authority
	// var update_auth = await find_pda_collection_owner();
	
	// console.log("Update auth", update_auth.toString());
	
	// const set_authority_instruction = Token.createSetAuthorityInstruction(TOKEN_PROGRAM_ID, mint.publicKey, update_auth, 0, wallet_provider.publicKey, [])
	
	var transaction = new Transaction();
	
	transaction.add(create_account_instruction);
	transaction.add(init_mint_instruction);
	transaction.add(metadata_instruction);
	transaction.add(create_ata_instruction);
	transaction.add(mint_to_instruction);
	transaction.add(create_master_edition_v3_instruction);
	// transaction.add(set_authority_instruction);
	
	transaction.feePayer = wallet_address;
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(mint);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('collection address', mint.publicKey.toString(), 'signature', signature);
	
	return mint.publicKey;	
}

/*
async function set_autority_to_pda(collection_address) {
	
	var update_auth = await find_pda_collection_owner();
	
	console.log("Update auth", update_auth.toString());
	
	var current_authority = new PublicKey('7tGJMU6N65UXRn9qPBpRvJZBcviwSSi52TUXYZg3AmWM');
	
	// const set_authority_instruction = Token.createSetAuthorityInstruction(TOKEN_PROGRAM_ID, collection_address, update_auth, 0, wallet_provider.publicKey, [])
	const set_authority_instruction = Token.createSetAuthorityInstruction(TOKEN_PROGRAM_ID, collection_address, update_auth, 0, current_authority, [])
	
	var transaction = new Transaction();
	
	transaction.add(set_authority_instruction);
	
	transaction.feePayer = new PublicKey(wallet_provider.publicKey);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(wallet_provider);
	
	let signature = await connection.sendRawTransaction(
		transaction.serialize(),
		{ skipPreflight: true }
	);	
	
	console.log('signature', signature);
}
*/

/**
 * 
 * Create vault
 * 
 */
export async function mint(wallet_provider, wallet_address, count, mint_type, collection_address, escrow_address, escrow_wl_address) {
	
	if(!connection)
		connection = await establishConnection();
	
	var transactions = [];
	
	for(var i = 0; i<count; i++) {
		
		let mint = await Keypair.generate();
		collection_address = new PublicKey(collection_address)
		wallet_address = new PublicKey(wallet_address)
		escrow_address = new PublicKey(escrow_address)
		
		let create_account_instruction = await prepare_create_account_instruction(wallet_address, mint);
		let init_mint_instruction = await prepare_init_mint_instruction(wallet_address, mint);
		let create_ata_instruction = await prepare_create_ata_instruction(wallet_address, mint);
		let create_mint_nft_instruction = await prepare_program_mint_instruction(wallet_provider, wallet_address, mint, collection_address, escrow_address, mint_type, escrow_wl_address);
		// var create_master_edition_v3_instruction = await prepare_create_master_edition_v3_instruction(wallet_address, mint);
		
		let transaction = new Transaction();
		
		transaction.add(create_account_instruction);
		transaction.add(init_mint_instruction);
		transaction.add(create_ata_instruction);
		transaction.add(create_mint_nft_instruction);
		// transaction.add(create_master_edition_v3_instruction);
		
		transaction.feePayer = new PublicKey(wallet_provider.publicKey);
		transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
		
		transaction.sign(mint);
		
		transactions.push(transaction);
	}
	
	var signatures = await signAndSendMultipleTransactions(wallet_provider, connection, transactions);
	
	return signatures;	
}

export async function create_escrow(wallet_provider, wallet_address, supply) {
	
	if(!connection)
		connection = await establishConnection();
	
	var escrow = await Keypair.generate();
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	const transaction = await program.transaction.createescrow(
		new anchor.BN(supply),
		{
			accounts: {
				
				projectOwner: wallet_address,
				escrowAccount: escrow.publicKey,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			instructions: [
				await program.account.escrowAccount.createInstruction(escrow),
			],
		}
	);
	
	transaction.feePayer = new PublicKey(wallet_address);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(escrow);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	
	return signature;
}

export async function update_supply(wallet_provider, wallet_address, supply, escrow_account) {
	
	if(!connection)
		connection = await establishConnection();
	
	var escrow = new PublicKey(escrow_account);
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	const transaction = await program.transaction.changesupply(
		new anchor.BN(supply),
		{
			accounts: {
				
				projectOwner: wallet_address,
				escrowAccount: escrow,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		}
	);
	
	transaction.feePayer = new PublicKey(wallet_address);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	
	return signature;
}




export async function create_escrow_wl_unlimited(wallet_provider, wallet_address, wl_wallet_address) {
	
	if(!connection)
		connection = await establishConnection();
	
	var escrow = await Keypair.generate();
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	const transaction = await program.transaction.createescrowwlunlimited(
		{
			accounts: {
				
				projectOwner: wallet_address,
				walletAddress: new PublicKey(wl_wallet_address),
				escrowAccountWl: escrow.publicKey,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			instructions: [
				await program.account.escrowAccountWlUnlimited.createInstruction(escrow),
			],
		}
	);
	
	transaction.feePayer = new PublicKey(wallet_address);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(escrow);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('escrow', escrow.publicKey.toString());
	console.log('signature', signature);
	
	return signature;
}

export async function create_escrow_wl_limited(wallet_provider, wallet_address, wl_wallet_address, spots) {
	
	if(!connection)
		connection = await establishConnection();
	
	var escrow = await Keypair.generate();
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	const transaction = await program.transaction.createescrowwllimited(
		new anchor.BN(spots),
		{
			accounts: {
				
				projectOwner: wallet_address,
				walletAddress: new PublicKey(wl_wallet_address),
				escrowAccountWl: escrow.publicKey,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			instructions: [
				await program.account.escrowAccountWlLimited.createInstruction(escrow),
			],
		}
	);
	
	transaction.feePayer = new PublicKey(wallet_address);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(escrow);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('escrow', escrow.publicKey.toString());
	console.log('signature', signature);
	
	return signature;
}

export async function check_escrow(wallet_provider, wallet_address, escrow_account) {
	
	if(!connection)
		connection = await establishConnection();
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var escrow_data = await program.account.escrowAccount.fetch(new PublicKey(escrow_account));
	
	escrow_data.supply = escrow_data.supply.toString()
	escrow_data.currentId = escrow_data.currentId.toString()
	
	return escrow_data;
}

export async function check_escrow_wl_unlimited(wallet_provider, wallet_address, escrow_account) {
	
	if(!connection)
		connection = await establishConnection();
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var escrow_data = await program.account.escrowAccountWlUnlimited.fetch(new PublicKey(escrow_account));
	
	escrow_data.wallet_address = escrow_data.walletAddress.toString()
	
	return escrow_data;
}

export async function check_escrow_wl_limited(wallet_provider, wallet_address, escrow_account) {
	
	if(!connection)
		connection = await establishConnection();
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var escrow_data = await program.account.escrowAccountWlLimited.fetch(new PublicKey(escrow_account));
	
	escrow_data.walletAddress = escrow_data.walletAddress.toString()
	escrow_data.spots = escrow_data.spots.toString()
	
	return escrow_data;
}



export async function fetch_all_escrow_collection() {
	
	if(!connection)
		connection = await establishConnection();
	
	var program = anchor_init(connection, null, program_id, idl);
	
	var escrows = await program.account.escrowAccount.all();
	
	var escrows_return = [];
	
	for(var escrow of escrows) {
		
		var escrow_data = {
			
			current_id: escrow.account.currentId.toString(),
			supply: escrow.account.supply.toString(),
			escrow_address: escrow.publicKey.toString(),
		};
		
		escrows_return.push(escrow_data);
	}
	
	return escrows_return;
}

export async function fetch_all_escrow_wl_limited() {
	
	if(!connection)
		connection = await establishConnection();
	
	var program = anchor_init(connection, null, program_id, idl);
	
	var escrows = await program.account.escrowAccountWlLimited.all();
	
	var escrows_return = [];
	
	for(var escrow of escrows) {
		
		var escrow_data = {
			
			wallet: escrow.account.walletAddress.toString(),
			spots: escrow.account.spots.toString(),
			escrow_address: escrow.publicKey.toString(),
		};
		
		escrows_return.push(escrow_data);
	}
	
	return escrows_return;
}

export async function fetch_all_escrow_wl_unlimited() {
	
	if(!connection)
		connection = await establishConnection();
	
	var program = anchor_init(connection, null, program_id, idl);
	
	var escrows = await program.account.escrowAccountWlUnlimited.all();
	
	var escrows_return = [];
	
	for(var escrow of escrows) {
		
		var escrow_data = {
			
			wallet: escrow.account.walletAddress.toString(),
			escrow_address: escrow.publicKey.toString(),
		};
		
		escrows_return.push(escrow_data);
	}
	
	return escrows_return;
}

export async function update_metadata(wallet_provider, mint) {
	
	if(!connection)
		connection = await establishConnection();
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	var update_autohority = await find_pda_collection_owner();
	
	var metadata_account = await find_pda(new PublicKey(mint));
	
	console.log({
		
		projectOwner: wallet_provider.publicKey,
		creator1: "8SZTojxgUPmKDkiBm4rg5K7tm6c1STLvn7L2a68jn9AA",
		creator2: "AuToz9gEG4f4fh67zo2EiwFKvW6tneMYE6UNDmX95nrL",
		verifiedCreator: "DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac",
		
		mplProgram: 'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s',
		metadataAccount: metadata_account.toString(),
		updateAuthority: update_autohority.toString(),
		
	});
			
	const transaction = await program.transaction.addverifiedcreator(
		{
			accounts: {
				
				projectOwner: new PublicKey(wallet_provider.publicKey),
				creator1: new PublicKey("8SZTojxgUPmKDkiBm4rg5K7tm6c1STLvn7L2a68jn9AA"),
				creator2: new PublicKey("AuToz9gEG4f4fh67zo2EiwFKvW6tneMYE6UNDmX95nrL"),
				verifiedCreator: new PublicKey("DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac"),
				
				metadataAccount: metadata_account,
				mplProgram: new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
				updateAuthority: update_autohority,
				
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		}
	);
	
	transaction.feePayer = new PublicKey(wallet_provider.publicKey);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('signature', signature);
	
	return signature;
}